import React from 'react';

export default class RegistrationInfo extends React.Component {
  render() {
    return (
      <div>
        <p>
          Registration includes daily breakfasts, morning and afternoon
          refreshments, light lunches on Monday (or Tuesday),
          Wednesday, and Friday, and evening refreshments at the hospitality
          suite.
          {/* Registration includes daily breakfasts, morning and afternoon
          refreshments, light lunches on Monday (for IT session attendees),
          Wednesday, and Friday, and evening refreshments at the hospitality
          suite. */}
        </p>
      </div>
    );
  }
}
